var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      value: _vm.value ? _vm.value : _vm.Model,
      "item-value": _vm.itemValue,
      "item-text": _vm.itemText,
      items: _vm.items,
      "return-object": _vm.returnObject,
      chips: _vm.multiple,
      attach: _vm.attach,
      "small-chips": _vm.multiple,
      "menu-props": _vm.dropDownPosition,
      dense: _vm.dense,
      readonly: _vm.readonly,
      multiple: _vm.multiple,
      label: _vm.label,
      clearable: _vm.clearable && !_vm.readonly,
      "error-count": _vm.errorCount(_vm.keyError),
      "error-messages": _vm.errors(_vm.keyError),
      error: _vm.hasErrors(_vm.keyError),
      "hide-details": "auto",
    },
    on: {
      input: function ($event) {
        return _vm.updateValue($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }