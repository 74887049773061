var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    { on: { click: _vm.clicked } },
    [
      _c("span", [_vm._v(" " + _vm._s(_vm.Text) + " ")]),
      _c("v-icon", [_vm._v(" " + _vm._s(_vm.Icon) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }