var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    { attrs: { inset: _vm.inset } },
    [
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", [
            _c("h3", [_vm._v(" " + _vm._s(_vm.header) + " ")]),
          ]),
          _c("v-expansion-panel-content", [_vm._t("default")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }