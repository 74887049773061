var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "text-center" },
        [
          _c(
            "v-col",
            { staticClass: "mb-4" },
            [
              _c("h1", { staticClass: "display-2 font-weight-bold mb-3" }, [
                _vm._v("Welcome inside"),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-img", {
                    staticClass: "my-3",
                    attrs: {
                      src: require("../../assets/logo-pga-complete-color.png"),
                      height: "400",
                      contain: "",
                    },
                  }),
                ],
                1
              ),
              _c("p", { staticClass: "subheading font-weight-regular" }, [
                _vm._v(
                  "The Enterprise Resource Planner for Corporate Governance"
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }