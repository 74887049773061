var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    { attrs: { value: _vm.myIsLoading, absolute: _vm.absolute } },
    [
      _c("v-progress-circular", {
        attrs: { indeterminate: "", color: "pimary", size: 150, width: 10 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }