var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { right: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: _vm.color,
                        "x-small": _vm.xSmall,
                        small: _vm.small,
                        large: _vm.large,
                        "x-large": _vm.xLarge,
                        medium: _vm.medium,
                        readonly: "",
                      },
                      domProps: { textContent: _vm._s(_vm.icon) },
                      on: { click: _vm.clicked },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }