var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isOK
    ? _c("v-skeleton-loader", {
        staticClass: "mx-auto",
        attrs: { "max-width": "600", type: "card", loading: "" },
      })
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }