var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    attrs: {
      value: _vm.Model,
      items: _vm.items,
      label: _vm.label,
      "item-text": _vm.itemText,
      multiple: _vm.multiple,
      "return-object": _vm.returnObject,
      dense: _vm.dense,
      "menu-props": { value: _vm.showMenu && !_vm.loading },
      readonly: _vm.readonly,
      "loader-height": 5,
      loading: _vm.loading,
      "search-input": _vm.searchInput,
      "error-count": _vm.errorCount(_vm.keyError),
      "error-messages": _vm.errors(_vm.keyError),
      error: _vm.hasErrors(_vm.keyError),
      "hide-details": "auto",
    },
    on: {
      input: function ($event) {
        return _vm.updateValue($event)
      },
      click: function ($event) {
        return _vm.changeShowMenu(!_vm.showMenu)
      },
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
        ) {
          return null
        }
        return _vm.changeShowMenu(false)
      },
      "update:searchInput": function ($event) {
        _vm.searchInput = $event
      },
      "update:search-input": function ($event) {
        _vm.searchInput = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }