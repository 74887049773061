var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", dark: "", "clipped-right": "", dense: _vm.isMobile } },
    [
      _vm.isMobile
        ? _c(
            "v-app-bar-nav-icon",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.drawer()
                },
              },
            },
            [_vm.accessibility ? _c("span", [_vm._v(" Menu ")]) : _vm._e()]
          )
        : _vm._e(),
      _c("v-spacer"),
      _vm.showNotifications
        ? _c("pga-menu-notification", {
            class: _vm.accessibility ? "mr-10" : "",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }