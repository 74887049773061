import { render, staticRenderFns } from "./PgaGeneralButton.vue?vue&type=template&id=67da09e4&"
import script from "./PgaGeneralButton.vue?vue&type=script&lang=ts&"
export * from "./PgaGeneralButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67da09e4')) {
      api.createRecord('67da09e4', component.options)
    } else {
      api.reload('67da09e4', component.options)
    }
    module.hot.accept("./PgaGeneralButton.vue?vue&type=template&id=67da09e4&", function () {
      api.rerender('67da09e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/buttons/PgaGeneralButton.vue"
export default component.exports