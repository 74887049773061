var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      staticClass: "overflow-y-auto",
      attrs: { nav: "", rounded: "", dense: "", "max-height": "400" },
    },
    [
      _c(
        "v-list-item-group",
        [
          _c("br", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.emptyNotification,
                expression: "!emptyNotification",
              },
            ],
          }),
          _vm._l(_vm.notifications, function (notification) {
            return _c(
              "v-list-item",
              {
                key: notification.Id,
                class: _vm.notRead(notification.Read),
                on: {
                  click: function ($event) {
                    return _vm.dialogNotification(notification)
                  },
                },
              },
              [
                _c("pga-single-notification", {
                  attrs: { notification: notification },
                }),
              ],
              1
            )
          }),
          _c(
            "v-list-item-content",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.emptyNotification,
                  expression: "emptyNotification",
                },
              ],
            },
            [
              _c("span", { attrs: { align: "center" } }, [
                _vm._v(" No new notifications yet? "),
              ]),
            ]
          ),
          _c("pga-dialog", {
            attrs: {
              title: _vm.dialogModel.Title,
              "icon-title": _vm.dialogModel.IconTitle,
              message: _vm.dialogModel.Message,
              "footer-message": _vm.dialogModel.FooterMessage,
              color: _vm.dialogModel.Color,
              "icon-button": _vm.dialogModel.IconButton,
              "only-agree": true,
            },
            on: { agree: _vm.hideDialog },
            model: {
              value: _vm.dialogModel.ShowDialog,
              callback: function ($$v) {
                _vm.$set(_vm.dialogModel, "ShowDialog", $$v)
              },
              expression: "dialogModel.ShowDialog",
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }