var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        value: _vm.buttons["delete"].Value,
        width: _vm.buttons["delete"].Width,
      },
      on: { click: _vm.clicked },
    },
    [
      _vm.buttons["delete"].Text
        ? _c("span", [_vm._v(" " + _vm._s(_vm.text) + " ")])
        : _vm._e(),
      _c("v-icon", { attrs: { color: "red" } }, [
        _vm._v(" " + _vm._s(_vm.buttons["delete"].Icon) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }