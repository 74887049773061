var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "elevation-12", attrs: { loading: _vm.isLoading } },
        [
          _c(
            "template",
            { slot: "progress" },
            [
              _c("v-progress-linear", {
                attrs: {
                  color: "deep-purple",
                  height: "10",
                  indeterminate: "",
                },
              }),
            ],
            1
          ),
          _c(
            "v-system-bar",
            { attrs: { color: "white" } },
            [
              _c("v-spacer"),
              _c("pga-tooltip-icon", {
                attrs: {
                  text: "Dismiss",
                  icon: "mdi-alpha-x-circle-outline",
                  color: "error",
                  large: "",
                },
                on: { click: _vm.ShowDialog },
              }),
            ],
            1
          ),
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "mx-0",
                  attrs: { align: "start", justify: "start" },
                },
                [
                  _c("v-col", { attrs: { cols: "12", lg: "9" } }, [
                    _c("h3", [_vm._v(" " + _vm._s(_vm.activity.Title) + " ")]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-0",
                  attrs: { dense: "", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { cols: "12", lg: "12" },
                    },
                    [_c("h6", [_vm._v(" " + _vm._s(_vm.CreatedAt) + " ")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-row", { staticClass: "mx-0", attrs: { align: "left" } }, [
                _c("div", { staticClass: "grey--text ml-4" }, [
                  _vm._v(" " + _vm._s(_vm.activity.Description) + " "),
                ]),
              ]),
              _c(
                "v-row",
                { staticClass: "mx-0", attrs: { justify: "center" } },
                [
                  _vm.message
                    ? _c("span", { staticClass: "red--text text--lighten-1" }, [
                        _vm._v(" " + _vm._s(_vm.message) + " "),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-4" }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "indigo lighten-2", text: "" },
                      on: { click: _vm.details },
                    },
                    [_vm._v(" Details ")]
                  ),
                  _vm.hasActions
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "red accent-4", text: "" },
                          on: { click: _vm.action },
                        },
                        [_vm._v(" Dispose ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("pga-dialog", {
        attrs: {
          title: _vm.titleDialog,
          "icon-title": "mdi-delete-alert",
          message: _vm.messageDialog,
          color: "orange",
          "icon-button": "mdi-checkbox-marked-circle-outline",
        },
        on: { agree: _vm.dismiss, reject: _vm.HideDialog },
        model: {
          value: _vm.showDialog,
          callback: function ($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }