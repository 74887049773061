import { render, staticRenderFns } from "./PgaLoadingSpinner.vue?vue&type=template&id=3f0965a7&"
import script from "./PgaLoadingSpinner.vue?vue&type=script&lang=ts&"
export * from "./PgaLoadingSpinner.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VOverlay,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f0965a7')) {
      api.createRecord('3f0965a7', component.options)
    } else {
      api.reload('3f0965a7', component.options)
    }
    module.hot.accept("./PgaLoadingSpinner.vue?vue&type=template&id=3f0965a7&", function () {
      api.rerender('3f0965a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PgaLoadingSpinner.vue"
export default component.exports