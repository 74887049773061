var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-progress-linear",
    {
      attrs: {
        color: _vm.color,
        height: _vm.height,
        value: _vm.value,
        rounded: "",
        dark: "",
        striped: _vm.stripped,
      },
    },
    [
      _c(
        "strong",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.content.length,
              expression: "content.length",
            },
          ],
        },
        [_vm._v(" " + _vm._s(_vm.textBar) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }