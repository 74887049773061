var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Activities",
      headers: _vm.headers,
      data: _vm.activities,
      loading: _vm.isLoading,
      "show-expand": "",
      "col-props": ["CreatedAt", "Type"],
    },
    scopedSlots: _vm._u([
      {
        key: "expand",
        fn: function (props) {
          return [
            _c("pga-activity-item", {
              attrs: {
                activity: props.row.item,
                colspan: props.row.headers.length,
              },
              on: {
                details: function ($event) {
                  return _vm.showDetails(props.row.item)
                },
              },
            }),
          ]
        },
      },
      {
        key: "column-CreatedAt",
        fn: function (props) {
          return [
            _vm._v(" " + _vm._s(props.row.item.CreatedAt.toUTCString()) + " "),
          ]
        },
      },
      {
        key: "column-Type",
        fn: function (props) {
          return [_vm._v(" " + _vm._s(_vm.typeText(props.row.item.Type)) + " ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }