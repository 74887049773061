var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.title ? "comp" : "" }, [
    _c(
      "div",
      {
        staticClass:
          " v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select v-text-field__slot",
      },
      [
        _vm.title
          ? _c(
              "label",
              {
                staticClass: "v-label v-label--active theme--light",
                staticStyle: {
                  left: "0px",
                  right: "auto",
                  position: "absolute",
                },
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          : _vm._e(),
        _c("v-switch", {
          attrs: {
            "input-value": _vm.Model,
            color: _vm.color,
            inset: _vm.inset,
            dense: _vm.dense,
            label: _vm.showLabel(),
            readonly: _vm.readonly,
            disabled: _vm.disabled,
            "hide-details": "",
          },
          on: {
            change: function ($event) {
              return _vm.updateValue($event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }