var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.buttons && _vm.buttons.length > 0,
          expression: "buttons && buttons.length > 0",
        },
      ],
      attrs: { fixed: "", app: "", "hide-on-scroll": "" },
      model: {
        value: _vm.bottomNav,
        callback: function ($$v) {
          _vm.bottomNav = $$v
        },
        expression: "bottomNav",
      },
    },
    [
      _c(
        "v-btn",
        {
          attrs: { value: _vm.buttons[0].Value },
          on: {
            click: function ($event) {
              return _vm.navigate(_vm.buttons[0].Url)
            },
          },
        },
        [
          _vm.buttons[0].Text
            ? _c("span", [_vm._v(_vm._s(_vm.buttons[0].Text))])
            : _vm._e(),
          _c("v-icon", [_vm._v(_vm._s(_vm.buttons[0].Icon))]),
        ],
        1
      ),
      _vm.isLoaded ? _vm._t("default") : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { value: _vm.buttons[1].Value },
          on: {
            click: function ($event) {
              return _vm.navigate(_vm.buttons[1].Url)
            },
          },
        },
        [
          _vm.buttons[1].Text
            ? _c("span", [_vm._v(_vm._s(_vm.buttons[1].Text))])
            : _vm._e(),
          _c("v-icon", [_vm._v(_vm._s(_vm.buttons[1].Icon))]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }