var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm.contentLength > _vm.maxContentLength
        ? _c(
            "div",
            [
              _vm.chips
                ? _c("v-chip", [_vm._v(" ... ")])
                : _c("span", [_vm._v(" ... ")]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }