var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      value: _vm.Model,
      label: _vm.label,
      type: !_vm.decimal ? "number" : "",
      dense: _vm.dense,
      prefix: _vm.prefix,
      suffix: _vm.suffix,
      readonly: _vm.readonly,
      "error-count": _vm.errorCount(_vm.keyError),
      "error-messages": _vm.errors(_vm.keyError),
      error: _vm.hasErrors(_vm.keyError),
      "hide-details": "auto",
    },
    on: {
      input: function ($event) {
        return _vm.updateValue($event)
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.decimal
          ? {
              key: "append-outer",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      on: { click: _vm.decrement },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" mdi-minus "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", small: "" },
                      on: { click: _vm.increment },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" mdi-plus "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }