var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("pga-fullscreen-loading-spinner"),
      _c("pga-app-bar"),
      _c("pga-navigation-drawer"),
      _c(
        "v-main",
        { staticClass: "grey lighten-4 fill-height" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("pga-transition", [_c("router-view")], 1),
              _c("pga-alert-error"),
              _c("pga-alert-warning"),
              _c("pga-send-email-to-permissions"),
              _c("pga-send-email-to-users"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }