var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-height": "450",
        "max-width": "450",
        transition: "fab-transition",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-system-bar",
            { attrs: { color: "white" } },
            [
              _c("v-spacer"),
              _c("pga-tooltip-icon", {
                attrs: {
                  text: "Close",
                  icon: "mdi-close",
                  color: "error",
                  large: "",
                },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
          _c(
            "v-card-title",
            { attrs: { dark: "" } },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-email-outline "),
              ]),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { dark: "", vertical: "" },
              }),
              _c("pga-text-field", {
                attrs: {
                  label: "Subject",
                  "key-error": "Subject",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.subject,
                  callback: function ($$v) {
                    _vm.subject = $$v
                  },
                  expression: "subject",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("pga-text-area", {
                attrs: {
                  label: "Body",
                  "key-error": "Body",
                  errors: _vm.Errors,
                },
                model: {
                  value: _vm.body,
                  callback: function ($$v) {
                    _vm.body = $$v
                  },
                  expression: "body",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("pga-tooltip-icon", {
                attrs: {
                  text: "Send",
                  icon: "mdi-send",
                  color: "blue",
                  large: "",
                },
                on: { click: _vm.send },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }