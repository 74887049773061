var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 400,
            "offset-x": "",
            left: "",
            transition: "slide-x-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var onTooltip = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "", dark: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    Object.assign({}, on, onTooltip)
                                  ),
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          content:
                                            _vm.notificationsNotReadLength(),
                                          value:
                                            _vm.notificationsNotReadLength(),
                                          color: "red",
                                          overlap: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(" mdi-bell-outline "),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.accessibility
                                      ? _c("span", [_vm._v(" Notifications ")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v(" Notifications ")])]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", dark: "", color: "grey" } },
            [
              _c("v-toolbar-title", [_vm._v(" NOTIFICATIONS ")]),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { small: "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.dismissNotifications.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-check-bold ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Dismiss")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { small: "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.reloadNotifications.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-reload ")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Reload")])]
              ),
            ],
            1
          ),
          _c("pga-notification-item", {
            attrs: { notifications: _vm.Notifications },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }