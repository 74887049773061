var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-menu", {
    attrs: {
      "close-on-content-click": false,
      transition: "scale-transition",
      "offset-y": "",
      "nudge-right": 40,
      disabled: _vm.readonly,
      "min-width": "auto",
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function (ref) {
          var on = ref.on
          var attrs = ref.attrs
          return [
            _c(
              "v-text-field",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      value: _vm.localeDate,
                      "prepend-icon": "mdi-calendar",
                      label: _vm.label,
                      readonly: "",
                      "error-count": _vm.errorCount(_vm.keyError),
                      "error-messages": _vm.errors(_vm.keyError),
                      error: _vm.hasErrors(_vm.keyError),
                    },
                  },
                  "v-text-field",
                  attrs,
                  false
                ),
                on
              )
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("v-date-picker", {
              attrs: { value: _vm.isoDate, readonly: _vm.readonly },
              on: {
                input: function ($event) {
                  return _vm.updateValue($event)
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showMenu,
      callback: function ($$v) {
        _vm.showMenu = $$v
      },
      expression: "showMenu",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }