var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.transitionName,
        mode: _vm.transitionMode,
        "enter-active-class": _vm.transitionEnterActiveClass,
      },
      on: {
        beforeLeave: _vm.beforeLeave,
        enter: _vm.enter,
        afterEnter: _vm.afterEnter,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }