var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-height": "450",
        "max-width": "450",
        transition: "fab-transition",
      },
      model: {
        value: _vm.Model,
        callback: function ($$v) {
          _vm.Model = $$v
        },
        expression: "Model",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { class: _vm.classCard, attrs: { dark: "" } },
            [
              _c("v-icon", { attrs: { large: "", color: _vm.darkenColor } }, [
                _vm._v(" " + _vm._s(_vm.IconTitle) + " "),
              ]),
              _c("v-divider", {
                staticClass: "mx-4",
                attrs: { dark: "", vertical: "" },
              }),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.Title) } }),
            ],
            1
          ),
          _c("v-card-subtitle"),
          _c("v-card-text", [
            _c("br"),
            _c("h3", {
              staticStyle: { "text-align": "center" },
              domProps: { innerHTML: _vm._s(_vm.Message) },
            }),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-card-subtitle", [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.FooterMessage) },
                }),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: _vm.Color, fab: "", dark: "", small: "" },
                  on: { click: _vm.agree },
                },
                [_c("v-icon", [_vm._v(" " + _vm._s(_vm.IconButton) + " ")])],
                1
              ),
              !_vm.OnlyAgree
                ? _c(
                    "v-btn",
                    {
                      attrs: { dark: "", fab: "", small: "" },
                      on: { click: _vm.reject },
                    },
                    [_c("v-icon", [_vm._v(" mdi-undo-variant ")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }