var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-loading-spinner", {
    attrs: { "is-loading": _vm.isLoading, absolute: false },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }