var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "2" } },
    [
      _vm.label
        ? _c("v-card-title", [_vm._v(" " + _vm._s(_vm.label) + " ")])
        : _vm._e(),
      _c("v-card-text", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }