var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticStyle: { "max-height": "100%" },
      attrs: {
        "expand-on-hover": _vm.navigation.expandOnHover,
        "mini-variant": _vm.navigation.miniVariant,
        app: "",
        dark: "dark",
      },
      on: {
        "update:miniVariant": function ($event) {
          return _vm.$set(_vm.navigation, "miniVariant", $event)
        },
        "update:mini-variant": function ($event) {
          return _vm.$set(_vm.navigation, "miniVariant", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "v-list-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.IsLogged,
                      expression: "IsLogged",
                    },
                  ],
                  key: "i",
                  attrs: { dark: "dark", link: "" },
                  on: { click: _vm.Logout },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s("mdi-logout") },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s("Logout") },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "px-2", attrs: { "two-line": "", dark: "dark" } },
        [
          _c("v-list-item-avatar", { staticClass: "px-0" }, [
            _c("img", {
              staticStyle: { "border-radius": "50%" },
              attrs: {
                alt: "Logo",
                src: "img/icons/Logo_PGA ICONA_maskable_72x72.png",
              },
            }),
          ]),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [_vm._v("PGA")]),
              _c("v-list-item-subtitle", [_vm._v("Governance")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      !_vm.IsLogged
        ? _c(
            "v-list-item",
            {
              key: "Home",
              attrs: { dark: "dark", link: "" },
              on: { click: _vm.Login },
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-account")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Login")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.IsLogged
        ? _c(
            "v-list-item",
            {
              key: "Home",
              staticStyle: { "padding-left": "8px" },
              attrs: { dark: "dark", link: "" },
              on: { click: _vm.Login },
            },
            [
              _c("v-list-item-avatar", { staticClass: "px-0" }, [
                _vm.LoggedUserImage != ""
                  ? _c("img", {
                      staticStyle: { "border-radius": "50%" },
                      attrs: { alt: "Image Profile", src: _vm.LoggedUserImage },
                    })
                  : _vm._e(),
                _vm.LoggedUserImage == ""
                  ? _c("img", {
                      staticStyle: { "border-radius": "50%" },
                      attrs: {
                        alt: "Image Profile",
                        src: require("../../../assets/img-avatar.png"),
                      },
                    })
                  : _vm._e(),
              ]),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(_vm.LoggedUserName) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        _vm._l(_vm.Menu, function (menu1st, k) {
          return _c("div", { key: k }, [
            menu1st.ChildrenMenus && menu1st.ChildrenMenus.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "v-list-group",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.IsLogged &&
                              _vm.checkChildrenPermission(
                                menu1st.ChildrenMenus
                              ),
                            expression:
                              "IsLogged && checkChildrenPermission(menu1st.ChildrenMenus)",
                          },
                        ],
                        attrs: { "prepend-icon": menu1st.Icon, value: false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            menu1st.DisplayName
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      _vm._l(menu1st.ChildrenMenus, function (menu2nd, i) {
                        return _c(
                          "div",
                          { key: i },
                          [
                            menu2nd.PermissionCode == null ||
                            _vm.Permissions.includes(menu2nd.PermissionCode)
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      to: "/" + menu2nd.Url,
                                      dark: "dark",
                                      link: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", {
                                          domProps: {
                                            textContent: _vm._s(menu2nd.Icon),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", {
                                      domProps: {
                                        textContent: _vm._s(
                                          menu2nd.DisplayName
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-list-item",
                      {
                        key: menu1st.title,
                        attrs: {
                          to: "/" + menu1st.Url,
                          dark: "dark",
                          link: "",
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v(_vm._s(menu1st.Icon))])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(menu1st.DisplayName)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }