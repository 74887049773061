var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activities.length == 0
    ? _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v(" No Activities "),
      ])
    : _c("pga-time-line", {
        attrs: { "list-items": _vm.activities, "left-content": "CreatedAt" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("pga-activity-single-item", {
                  attrs: { activity: props.content },
                  on: {
                    details: function ($event) {
                      return _vm.showDetails(props.content)
                    },
                    dismiss: function ($event) {
                      return _vm.dismissActivity(props.content.Id)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }