var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "start" } },
        [
          _c("pga-text-field", {
            attrs: { label: "Search" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "start" } },
        [
          _c(
            "v-timeline",
            { attrs: { dense: _vm.dense } },
            _vm._l(_vm.listItemsFiltered, function (item, key) {
              return _c(
                "v-timeline-item",
                { key: key },
                [_vm._t("default", null, { content: item })],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }