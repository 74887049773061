var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTimeline
    ? _c("pga-activities-list-items", {
        attrs: { activities: _vm.listActivities, "is-loading": _vm.isLoading },
      })
    : _c("pga-activities-list", {
        attrs: { activities: _vm.listActivities, "is-loading": _vm.isLoading },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }