import { render, staticRenderFns } from "./PgaAuthorizedContent.vue?vue&type=template&id=480fb064&"
import script from "./PgaAuthorizedContent.vue?vue&type=script&lang=ts&"
export * from "./PgaAuthorizedContent.vue?vue&type=script&lang=ts&"
import style0 from "./PgaAuthorizedContent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('480fb064')) {
      api.createRecord('480fb064', component.options)
    } else {
      api.reload('480fb064', component.options)
    }
    module.hot.accept("./PgaAuthorizedContent.vue?vue&type=template&id=480fb064&", function () {
      api.rerender('480fb064', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PgaAuthorizedContent.vue"
export default component.exports