var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-file-input", {
    attrs: {
      value: _vm.value ? _vm.value : _vm.Model,
      label: _vm.label,
      multiple: _vm.multiple,
      chips: "",
      "hide-input": _vm.hideInput,
      "show-size": "",
      "truncate-length": "15",
      readonly: _vm.readonly,
      "error-count": _vm.errorCount(_vm.keyError),
      "error-messages": _vm.errors(_vm.keyError),
      error: _vm.hasErrors(_vm.keyError),
    },
    on: { change: _vm.updateValue },
    scopedSlots: _vm._u(
      [
        {
          key: "selection",
          fn: function (ref) {
            var file = ref.file
            var index = ref.index
            var multiple = ref.multiple
            var text = ref.text
            return [
              _vm._t("selection", null, {
                row: {
                  file: file,
                  index: index,
                  multiple: multiple,
                  text: text,
                },
              }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }