var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "start" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto", attrs: { cols: "auto", sm: "9" } },
            [
              _c("v-list-item-title", [
                _c("h3", [_vm._v(_vm._s(_vm.notification.Title))]),
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(_vm.notification.Description) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto", sm: "1" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.notification.Read,
                      expression: "!notification.Read",
                    },
                  ],
                  attrs: { small: "", color: "grey darken-3", icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.dismissSingleNotification(_vm.notification.Id)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-checkbox-blank-circle ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto", sm: "1" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.deleteSingleNotification(_vm.notification.Id)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-delete-outline ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "end", dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-list-item-title", [
                _vm._v(
                  " " + _vm._s(_vm.timeAgo(_vm.notification.CreatedAt)) + " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }