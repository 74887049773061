var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12", sm: "7" } }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: _vm.searchColumn.length > 0 ? "3" : "5",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                      readonly: _vm.AdvancedSearch,
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _vm.searchColumn.length > 0
                    ? _c("pga-switch", {
                        staticClass: "switch",
                        attrs: { title: "Advanced Search" },
                        model: {
                          value: _vm.AdvancedSearch,
                          callback: function ($$v) {
                            _vm.AdvancedSearch = $$v
                          },
                          expression: "AdvancedSearch",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.AdvancedSearch ? _c("br") : _vm._e(),
      _vm.AdvancedSearch
        ? _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.searchColumn, function (sc, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          attrs: {
                            cols: "12",
                            xs: "12",
                            sm: "12",
                            md: "4",
                            lg: "4",
                            xl: "3",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: { label: sc.columnName, dense: "" },
                            model: {
                              value: _vm.searchColumn[i].value,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchColumn[i], "value", $$v)
                              },
                              expression: "searchColumn[i].value",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.AdvancedSearch ? _c("br") : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-10",
        attrs: {
          value: _vm.Model,
          headers: _vm.headers,
          items: _vm.data,
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          "item-key": _vm.itemKey,
          search: _vm.Search,
          "sort-by": _vm.sortBy,
          "disable-pagination": _vm.disablePagination,
          "sort-desc": _vm.sortDesc,
          "custom-filter": _vm.customFilter,
          expanded: _vm.expanded,
          "show-expand": _vm.showExpand,
          "expand-icon": _vm.expandIcon,
          "show-select": _vm.showSelect,
          "single-select": _vm.singleSelect,
          "hide-default-footer": _vm.disablePagination,
          "group-by": _vm.groupBy,
          "show-group-by": _vm.showGroupBy,
        },
        on: {
          input: function ($event) {
            return _vm.updateValue($event)
          },
          "click:row": _vm.rowClicked,
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.showExpand
              ? {
                  key: "expanded-item",
                  fn: function (ref) {
                    var headers = ref.headers
                    var item = ref.item
                    return [
                      _vm._t("expand", null, {
                        row: { headers: headers, item: item },
                      }),
                    ]
                  },
                }
              : null,
            _vm._l(_vm.colProps, function (col) {
              return {
                key: "item." + col,
                fn: function (item) {
                  return [
                    col !== ""
                      ? _vm._t("column-" + col, null, { row: item })
                      : _vm._e(),
                  ]
                },
              }
            }),
            {
              key: "footer",
              fn: function () {
                return [_vm._t("footer")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }