var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { attrs: { colspan: _vm.colspan - 1 } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", sm: "8", xs: "10" } }, [
            _c("span", [_vm._v(_vm._s(_vm.activity.Description))]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "text-right",
              attrs: { cols: "12", sm: "2", xs: "1" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.details },
                },
                [_vm._v(" Details ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-right",
              attrs: { cols: "12", sm: "2", xs: "1" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", color: "error" },
                  on: { click: _vm.action },
                },
                [_vm._v(" Dispose ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "mx-0", attrs: { justify: "center" } }, [
        _vm.message
          ? _c("span", { staticClass: "red--text text--lighten-1" }, [
              _vm._v(" " + _vm._s(_vm.message) + " "),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }