var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    { attrs: { value: _vm.isVisibleAlert, absolute: _vm.absolute } },
    [
      _c(
        "v-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisibleAlert,
              expression: "isVisibleAlert",
            },
          ],
          attrs: {
            rounded: "",
            prominent: "",
            mode: "appear-to-class",
            type: "warning",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { staticClass: "grow" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.warningMessage) },
                }),
              ]),
              _c(
                "v-col",
                { staticClass: "shrink" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "aria-label": "Close Warning Alert" },
                      on: { click: _vm.clicked },
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }