var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-slider", {
        attrs: {
          value: _vm.Model || _vm.value,
          "tick-labels": _vm.arrayLabel,
          min: _vm.min,
          max: _vm.max,
          step: _vm.step,
          ticks: "always",
          "tick-size": _vm.tickSize,
          readonly: _vm.readonly,
        },
        on: {
          input: function ($event) {
            return _vm.updateValue($event)
          },
        },
      }),
      _c("v-slide-y-transition", [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showError,
                expression: "showError",
              },
            ],
            staticClass: "red--text text--lighten-1",
          },
          [_vm._v(" " + _vm._s(_vm.showErrorMessage) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }