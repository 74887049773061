import { render, staticRenderFns } from "./PgaAlertError.vue?vue&type=template&id=445163f6&"
import script from "./PgaAlertError.vue?vue&type=script&lang=ts&"
export * from "./PgaAlertError.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VIcon,VOverlay,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('445163f6')) {
      api.createRecord('445163f6', component.options)
    } else {
      api.reload('445163f6', component.options)
    }
    module.hot.accept("./PgaAlertError.vue?vue&type=template&id=445163f6&", function () {
      api.rerender('445163f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PgaAlertError.vue"
export default component.exports