var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        fab: _vm.fab,
        dark: _vm.dark,
        color: _vm.color,
        absolute: _vm.absolute,
        right: _vm.right,
        bottom: _vm.bottom,
        large: _vm.large,
        small: _vm.small,
        "x-small": _vm.xSmall,
        icon: _vm.asIcon,
      },
      on: { click: _vm.clicked },
    },
    [
      _c("span", [_vm._v(" " + _vm._s(_vm.text) + " ")]),
      _c("v-icon", { attrs: { color: _vm.iconColor } }, [
        _vm._v(" " + _vm._s(_vm.icon) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }